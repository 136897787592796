import { Link } from 'react-router-dom';
import styles from './Button.module.scss';

function Button({
  href,
  buttonStyle = 'primary',
  size = '',
  children,
  wide = false,
  type = 'button',
  ...props
}: {
  onClick?: () => void;
  href?: string;
  buttonStyle?:
    | 'primary'
    | 'secondary'
    | 'text-link'
    | 'text-link--grey'
    | 'danger'
    | 'square'
    | 'faded'
    | 'rounded-faded'
    | 'rounded-green';
  size?: string;
  children: React.ReactNode;
  wide?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}) {
  if (href) {
    return (
      <Link
        className={`${styles.button} ${styles[buttonStyle]} ${styles[size]} ${
          wide && styles['wide']
        }`}
        to={href}
        disabled={props.disabled}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`${styles.button} ${styles[buttonStyle]} ${styles[size]} ${
        wide && styles['wide']
      }`}
      type={type}
      onClick={props.onClick}
      disabled={props.disabled}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
