import styles from './TimeOverviewView.module.scss';
import Tabs from '../../components/tabs/Tabs';
import Topbar from '../../components/topbar/Topbar';
import DefaultLayout from '../../layouts/Default';
import InputField from '../../components/inputfield/InputField';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { TimeLogInterface } from '../../types/Time';
import { collection, where, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import TimeSummary from '../../components/time/summary/TimeSummary';
import { calculateSummaryNumbers } from '../../utils/calculateSummaryNumbers';
import { currentWeek } from '../../utils/currentWeek';

// Function to show a list of years based on the current year back to 2024
const yearsToSelect = Array.from(
  { length: new Date().getFullYear() - 2024 + 1 },
  (_, i) => new Date().getFullYear() - i
).map((year) => ({
  value: year.toString(),
  label: year.toString(),
}));

const getWeeksInYear = () => {
  const weeks = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Find the first Monday of the year
  const firstDayOfYear = new Date(currentYear, 0, 1);
  while (firstDayOfYear.getDay() !== 1) {
    firstDayOfYear.setDate(firstDayOfYear.getDate() + 1);
  }

  // Generate weeks up to the current week
  for (
    let weekNumber = 1;
    weekNumber <= currentWeek(currentDate);
    weekNumber++
  ) {
    weeks.push({
      value: weekNumber.toString(),
      label: weekNumber.toString(),
    });
  }

  return weeks;
};

const getMonthsInYear = () => {
  const months = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Find the first Monday of the year
  const firstDayOfYear = new Date(currentYear, 0, 1);
  while (firstDayOfYear.getDay() !== 1) {
    firstDayOfYear.setDate(firstDayOfYear.getDate() + 1);
  }

  // Generate months up to the current month
  for (
    let monthNumber = 0;
    monthNumber <= currentDate.getMonth();
    monthNumber++
  ) {
    const label = new Date(currentYear, monthNumber, 1).toLocaleString(
      'da-DK',
      {
        month: 'long',
      }
    );

    months.push({
      value: monthNumber.toString(),
      label: label.charAt(0).toUpperCase() + label.slice(1),
    });
  }

  return months;
};

const getFirstDayOfMonth = () => {
  const date = new Date();
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
};

const getLastDayOfMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  date.setHours(23, 59, 59, 999);
  return date;
};

const getFirstDayOfWeek = () => {
  const date = new Date();
  // Set first day of the week to Monday
  date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
  date.setHours(0, 0, 0, 0);
  return date;
};

const getLastDayOfWeek = () => {
  const date = new Date();
  // Set last day of the week to Sunday
  date.setDate(date.getDate() - date.getDay() + 7);
  date.setHours(23, 59, 59, 999);

  return date;
};

function TimeOverviewView() {
  const { user, userInfo, company } = useContext(UserContext);
  const intervalStartDateRef = useRef<HTMLInputElement>(null);
  const intervalEndDateRef = useRef<HTMLInputElement>(null);
  const [activeTab, setActiveTab] = useState('week');
  const [startDate, setStartDate] = useState(getFirstDayOfWeek);
  const [endDate, setEndDate] = useState(getLastDayOfWeek);
  const [numbers, setNumbers] = useState({
    normTime: 0,
    total: 0,
    overtime: {
      label: 'Arbejdstid uden for norm',
      count: 0,
      hours: 0,
    },
    timeOff: {
      label: 'Fri',
      count: 0,
      hours: 0,
    },
    holiday: {
      label: 'Ferie',
      count: 0,
      hours: 0,
    },
    sick: {
      label: 'Syg',
      count: 0,
      hours: 0,
    },
    work: {
      label: 'Arbejdstid',
      count: 0,
      hours: 0,
    },
  });

  const handleWeekChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { value } = event.target;
    const weekNumber = parseInt(value, 10);

    // Calculate the start date of the selected week
    const firstMondayOfYear = new Date(startDate.getFullYear(), 0, 1);
    while (firstMondayOfYear.getDay() !== 1) {
      firstMondayOfYear.setDate(firstMondayOfYear.getDate() + 1);
    }

    const newStartDate = new Date(firstMondayOfYear);
    newStartDate.setDate(newStartDate.getDate() + (weekNumber - 1) * 7);
    newStartDate.setHours(0, 0, 0, 0);
    setStartDate(newStartDate);

    const newEndDate = new Date(newStartDate);
    newEndDate.setDate(newEndDate.getDate() + 6);
    newEndDate.setHours(23, 59, 59, 999);
    setEndDate(newEndDate);

    fetchLogsByDates(newStartDate, newEndDate);
  };

  const handleMonthChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { value } = event.target;

    const newStartDate = new Date();
    newStartDate.setMonth(parseInt(value));
    newStartDate.setDate(1);
    newStartDate.setHours(0, 0, 0, 0);
    setStartDate(newStartDate);

    const newEndDate = new Date(newStartDate);
    newEndDate.setMonth(newEndDate.getMonth() + 1);
    newEndDate.setDate(0);
    newEndDate.setHours(23, 59, 59, 999);
    setEndDate(newEndDate);

    fetchLogsByDates(newStartDate, newEndDate);
  };

  const handleYearChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { value } = event.target;

    const newStartDate = new Date();
    newStartDate.setFullYear(parseInt(value));
    newStartDate.setMonth(0);
    newStartDate.setDate(1);
    newStartDate.setHours(0, 0, 0, 0);
    setStartDate(newStartDate);

    const newEndDate = new Date(newStartDate);
    newEndDate.setFullYear(newEndDate.getFullYear() + 1);
    newEndDate.setDate(0);
    newEndDate.setHours(23, 59, 59, 999);
    setEndDate(newEndDate);

    fetchLogsByDates(newStartDate, newEndDate);
  };

  const handleIntervalChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    if (name === 'from') {
      if (value) {
        const [year, month, day] = value.split('-');
        const newStartDate = new Date(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day)
        );
        setStartDate(newStartDate);

        // Ensure endDate is still valid and fetch logs if so
        const endDateValue = intervalEndDateRef.current?.value;
        if (endDateValue) {
          const [endYear, endMonth, endDay] = endDateValue.split('-');
          const newEndDate = new Date(
            parseInt(endYear),
            parseInt(endMonth) - 1,
            parseInt(endDay)
          );

          if (newEndDate >= newStartDate) {
            fetchLogsByDates(newStartDate, newEndDate);
          }
        }
      }
    } else if (name === 'to') {
      if (value) {
        const [year, month, day] = value.split('-');
        const newEndDate = new Date(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day)
        );
        setEndDate(newEndDate);

        // Ensure startDate is still valid and fetch logs if so
        const startDateValue = intervalStartDateRef.current?.value;
        if (startDateValue) {
          const [startYear, startMonth, startDay] = startDateValue.split('-');
          const newStartDate = new Date(
            parseInt(startYear),
            parseInt(startMonth) - 1,
            parseInt(startDay)
          );

          if (newEndDate >= newStartDate) {
            fetchLogsByDates(newStartDate, newEndDate);
          }
        }
      }
    }
  };

  const fetchLogsByDates = async (startDate: Date, endDate: Date) => {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    // Fetch the logs collection of a user from the database based on the dates
    const timeLogsCollectionRef = collection(db, 'users', user.uid, 'logs');
    const q = query(
      timeLogsCollectionRef,
      where('date', '>=', startDate),
      where('date', '<=', endDate)
    );

    const querySnapshot = await getDocs(q);

    let logs: TimeLogInterface[] = [];
    querySnapshot.forEach((doc) => {
      logs.push({
        id: doc.id,
        data: {
          date: doc.data().date,
          entries: doc.data().entries,
          logs: doc.data().logs,
        },
      });
    });

    // Calculate numbers based on logs
    setNumbers(
      calculateSummaryNumbers(logs, startDate, endDate, company, userInfo)
    );
  };

  useEffect(() => {
    if (activeTab === 'week') {
      setStartDate(getFirstDayOfWeek());
      setEndDate(getLastDayOfWeek());
      fetchLogsByDates(getFirstDayOfWeek(), getLastDayOfWeek());
    } else if (activeTab === 'month') {
      setStartDate(getFirstDayOfMonth());
      setEndDate(getLastDayOfMonth());
      fetchLogsByDates(getFirstDayOfMonth(), getLastDayOfMonth());
    } else if (activeTab === 'yearly') {
      setStartDate(new Date(new Date().getFullYear(), 0, 1));
      setEndDate(new Date(new Date().getFullYear(), 11, 31));
      fetchLogsByDates(
        new Date(new Date().getFullYear(), 0, 1),
        new Date(new Date().getFullYear(), 11, 31)
      );
    } else if (activeTab === 'interval') {
      setStartDate(new Date());
      setEndDate(new Date());
      fetchLogsByDates(new Date(), new Date());
    } else {
      setStartDate(getFirstDayOfMonth());
      setEndDate(getLastDayOfMonth());
      fetchLogsByDates(getFirstDayOfMonth(), getLastDayOfMonth());
    }
  }, [activeTab]);

  return (
    <DefaultLayout>
      <Topbar center={<h4>Overblik</h4>} />
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          {
            value: 'week',
            label: 'Uge',
          },
          {
            value: 'month',
            label: 'Måned',
          },
          {
            value: 'yearly',
            label: 'Årligt',
          },
          {
            value: 'interval',
            label: 'Interval',
          },
        ]}
      />
      {activeTab === 'week' ? (
        <div className={styles.TimeOverviewViewFilter}>
          <InputField
            name="week"
            label="Uge"
            type="select"
            placeholder="- Vælg -"
            onChange={handleWeekChange}
            value={currentWeek(startDate).toString()}
            options={getWeeksInYear()}
          />
        </div>
      ) : activeTab === 'month' ? (
        <div className={styles.TimeOverviewViewFilter}>
          <InputField
            name="month"
            label="Måned"
            type="select"
            placeholder="- Vælg -"
            onChange={handleMonthChange}
            value={startDate.getMonth().toString()}
            options={getMonthsInYear()}
          />
        </div>
      ) : activeTab === 'yearly' ? (
        <div className={styles.TimeOverviewViewFilter}>
          <InputField
            name="year"
            label="År"
            type="select"
            placeholder="- Vælg -"
            value={startDate.getFullYear().toString()}
            onChange={handleYearChange}
            options={yearsToSelect}
          />
        </div>
      ) : activeTab === 'interval' ? (
        <div className={styles.TimeOverviewViewFilter}>
          <div className={styles.TimeOverviewViewFilterRow}>
            <InputField
              className={styles.TimeOverviewViewFilterRowInput}
              name="from"
              label="Fra"
              type="date"
              placeholder="dd.mm.åååå"
              value={startDate.toISOString().split('T')[0]}
              onChange={handleIntervalChange}
              forwardRef={intervalStartDateRef}
              visibleLabel
            />
            <InputField
              className={styles.TimeOverviewViewFilterRowInput}
              name="to"
              label="Til"
              type="date"
              placeholder="dd.mm.åååå"
              value={endDate.toISOString().split('T')[0]}
              onChange={handleIntervalChange}
              forwardRef={intervalEndDateRef}
              visibleLabel
            />
          </div>
        </div>
      ) : null}
      <TimeSummary numbers={numbers} />
    </DefaultLayout>
  );
}

export default TimeOverviewView;
