import styles from './TimeHistoryModal.module.scss';
import { TimeLogLogType } from '../../../types/Time';
import TIME_LOG_ACTION_TYPES from '../../../constants/timeLogActionTypes';
import TIME_LOG_ENTRY_TYPES from '../../../constants/timeLogEntryTypes';
import { useContext, useEffect, useRef } from 'react';
import Button from '../../button/Button';
import { AdminContext } from '../../../contexts/AdminContext';
import { UserContext } from '../../../contexts/UserContext';

function TimeHistoryModal({
  isModalOpen,
  setIsModalOpen,
  logs,
}: {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  logs: TimeLogLogType[];
}) {
  const { users } = useContext(AdminContext);
  const { user } = useContext(UserContext);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleCloseOnBackdrop = (e: React.MouseEvent) => {
    if (e.target !== modalRef.current) {
      return;
    }

    if (setIsModalOpen) {
      setIsModalOpen(false);
    }
  };

  const handleClose = () => {
    if (setIsModalOpen) {
      setIsModalOpen(false);
    }
  };

  // Lock scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [isModalOpen]);

  const sortedLogs = logs.sort((a, b) => {
    return b.timestamp.seconds - a.timestamp.seconds;
  });

  return (
    <div
      className={
        isModalOpen
          ? `${styles.timeHistoryModal} ${styles.timeHistoryModalOpen}`
          : styles.timeHistoryModal
      }
      ref={modalRef}
      onClick={handleCloseOnBackdrop}
    >
      <div className={styles.timeHistoryModalInner}>
        <h4>Historik</h4>
        <div className={styles.timeHistoryModalEntries}>
          {sortedLogs?.map((log) => {
            const authorUser =
              users.find((user) => user.id === log.author) || null;

            const isCurrentUser = user?.uid === log.author;

            return (
              <div className={styles.timeHistoryModalEntry} key={log.id}>
                {isCurrentUser
                  ? 'Du'
                  : authorUser
                  ? `${authorUser.firstName} ${authorUser.lastName}`
                  : 'Du'}{' '}
                {log.action === 'create_entry' ? (
                  <>
                    {
                      TIME_LOG_ACTION_TYPES.find(
                        (action) => action.value === log.action
                      )?.label
                    }{' '}
                    {log.details.hours} time{log.details.hours > 1 ? 'r' : ''}{' '}
                    på{' '}
                    {TIME_LOG_ENTRY_TYPES.find(
                      (entry) => entry.value === log.details.type
                    )?.label.toLowerCase()}
                  </>
                ) : log.action === 'update_entry' ? (
                  <>
                    {
                      TIME_LOG_ACTION_TYPES.find(
                        (action) => action.value === log.action
                      )?.label
                    }{' '}
                    fra {log.details.previousHours} til {log.details.hours} time
                    {log.details.hours > 1 ? 'r' : ''} på{' '}
                    {TIME_LOG_ENTRY_TYPES.find(
                      (entry) => entry.value === log.details.type
                    )?.label.toLowerCase()}
                  </>
                ) : (
                  <>
                    {
                      TIME_LOG_ACTION_TYPES.find(
                        (action) => action.value === log.action
                      )?.label
                    }{' '}
                    en tid på{' '}
                    {TIME_LOG_ENTRY_TYPES.find(
                      (entry) => entry.value === log.details.type
                    )?.label.toLowerCase()}
                  </>
                )}
                .
                <div className={styles.timeHistoryModalEntryDate}>
                  {new Date(log.timestamp.toDate()).toLocaleString('da-DK', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <Button wide onClick={handleClose}>
          Luk
        </Button>
      </div>
    </div>
  );
}

export default TimeHistoryModal;
