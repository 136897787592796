import { createContext } from 'react';

type AdminContextType = {
  owner: {
    firstName: string;
    lastName: string;
  };
  users: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
};

export const AdminContext = createContext({
  owner: {
    firstName: '',
    lastName: '',
  },
  users: [],
} as AdminContextType);
